import React from "react";
import Section from "../../components/Section/Section";
import fadeInSlide from 'utils/animations/fade-in-slide';

import "./OurPartners.scss";

const OurPartners = ({ description, title, clientsLogos }) => {
  const animateInOptions = {stagger: 0};
  const [ cardsRef ] = fadeInSlide('y', 25, { animateChildren: true, animateInOptions });
  const [ titleRef ] = fadeInSlide('y', -25);
  return (
    <Section className="OurPartners">
      <h3 ref={titleRef} className="alpha0">{title}</h3>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="OurPartnersDescription col-lg-6 Body--small">{description}</div>
        </div>
        <div ref={cardsRef} className="row align-items-center justify-content-center alpha0-children">
          {clientsLogos.map((logo, i) => {
            const image = (logo.logo.image)
              ? logo.logo.image
              : <img src={logo.logo.localFile.publicURL} alt={logo.logo.altText} />;

              return (
              <div className="LogoBox" key={i}>
                {image}
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default OurPartners;
